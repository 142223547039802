import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    selected: [],
  },
})
  .get({
    action: 'getFormDefinitions',
    path: () => '/forms',
  })
  .get({
    action: 'getFormDefinition',
    path: ({ formId }) => `/forms/${formId}`,
  })
  .post({
    action: 'createFormDefinition',
    path: () => '/forms',
  })
  .put({
    action: 'updateFormDefinition',
    path: ({ formId }) => `/forms/${formId}`,
  })
  .put({
    action: 'updateFormDefinitionDetails',
    path: ({ formId }) => `/forms/${formId}/details`,
  })
  .post({
    action: 'cloneFormDefinition',
    path: ({ formId }) => `/forms/${formId}/clone`,
  })
  .delete({
    action: 'deleteFormDefinition',
    path: ({ formId }) => `/forms/${formId}`,
  })
  .getStore();

export default store;
